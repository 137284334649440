@font-face {
    font-family: Aldrich;
    src: url(./Fonts/Aldrich-Regular.ttf);
}

body {
    width: 100%;
    min-height: 100dvh;
    border: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Aldrich';
    letter-spacing: 1px;
}