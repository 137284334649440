.end__container {
    width: 100%;
    min-height: 100dvh;
    overflow-y: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #232D7D;
    color: white;
    font-family:'Sofia', sans-serif;
}

.end {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100dvh;
}

.end__top {
    width: calc(100% - 48px);
    padding-inline: 24px;
    display: flex;
    justify-content: flex-start;
    background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0));
}

.end__top img {
    height: 64px;
    margin: 24px 0 48px;
}

.end__content {
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding-inline: 24px;
    max-width: 1000px;
    text-align: start;
}

.end > img {
    width: calc(100% - 48px);
    margin-bottom: 96px;
    max-width: 1000px;
}