.error__container {
    width: 100%;
    min-height: 100dvh;
    overflow-y: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #232D7D;
    color: white;
    font-family:'Sofia', sans-serif;
}

.error {
    display: flex;
    align-items: center;
    text-align: center;
    max-width: 1000px;
    gap: 64px;
    margin-inline: 24px;
}

.error__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 16px;
}

.error img {
    width: 100px;
}

@media (max-width: 750px) {
    .error {
        flex-direction: column;
        align-items: flex-start;
    }
}