.survey__container {
    width: 100%;
    min-height: 100dvh;
    overflow-y: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #232D7D;
    color: white;
    font-family:'Sofia', sans-serif;
    position: relative;
}

.survey {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 100dvh;
    justify-content: space-between;
}

.survey__bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 48px 24px 24px 24px;
    width: calc(100% - 48px);
    position: relative;
    z-index: 3;
    gap: 24px;
    background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0));
}

.survey__logo {
    width: 64px;
}

.avatar-interface {
    position: fixed;
    height: 100dvh;
    max-width: 100vw;
}

/* .avatar-interface video {
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}  */

.avatar-interface video {
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
} 

.survey__question-box {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.survey__question-background {
    width: 100%;
    background: linear-gradient(rgba(255,255,255,0), white);
    display: flex;
    justify-content: center;
}

.survey__question {
    max-width: 600px;
    margin: 80px 24px 24px 24px;
    background-color: white;
    border-radius: 24px;
    padding: 24px;
    color: black;
}

.survey__question h3 {
    color: #111C0F;
    font-size: 1rem;
    font-weight: 700;
}

.survey__question p {
    margin-top: 10px;
    font-size: 0.85rem;
    line-height: 22px;
    color: #111C0F;
    font-family: 'Sofia', sans-serif;
    font-weight: 400;
}

.survey__question-buttons {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
    gap: 10px;
}

.survey__question-button {
    width: calc(100% - 48px);
    max-width: 600px;
    border: 2px solid #232D7D;
    outline: none;
    background-color: white;
    color: #232D7D;
    font-family: 'Sofia', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    padding: 14px;
    border-radius: 64px;
    cursor: pointer;
    transition: all 0.2s;
}

.survey__question-button:hover, .survey__question-button:focus {
    color: white;
    background-color: #232D7D;
}

.survey__question-button:disabled {
    background-color: rgb(204, 204, 204) ;
    cursor: default;
    color: #232D7D;
}

.survey__question-button.selected {
    color: white;
    background-color: #232D7D !important;
}

ul {
    border: 2px solid rgb(0, 255, 0);
    max-width: 1000px;
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    z-index: 2;
}

li {
    margin-left: 16px;
    width: calc(100% - 32px);
    list-style: none;
    font-size: 14px;
    color: white;
}

ul .user b {
    color: yellow;
}

ul .ai b {
    color: rgb(0, 255, 0);
}

.survey__user-area {
    display: flex;
    width: 100%;
    justify-content: center;
    max-width: 1000px;
    gap: 16px;
    margin-top: 32px;
    position: relative;
    z-index: 2;
}

.survey__user-area textarea {
    flex: 1;
    resize: none;
    font-family:'Arial';
    line-height: 1.5;
    padding: 8px;
}

fieldset {
    border: 2px solid rgb(0, 255, 0);
}

h2 {
    text-align: center;
}

.avatar-interface {
    max-width: 100vw;
}