.audio-manager__current-device {
    display: flex;
    align-items: center;
    gap: 24px;
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    border-radius: 16px;
    padding: 4px 0px 4px 16px;
    cursor: pointer;
    background: #f9f9f9;
    color: black;
    font-family:'Sofia', sans-serif;
    width: 100%;
}

.audio-manager__current-device p {
    overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1; 
   -webkit-box-orient: vertical;
}

.audio-manager__current-device img {
    width: 24px;
}

.audio-manager__container {
    position: relative;
    margin-inline: 24px;
    margin-top: 24px;
    min-width: calc(100% - 48px);
    max-width: 500px;
    font-family:'Sofia', sans-serif;
}

.audio-manager__container ul {
    position: absolute;
    border: none;
    padding: 8px 0;
    margin: 0;
    background-color: white;
    border-radius: 16px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 0;
}

.audio-manager__container li {
    padding: 8px 0;
    border-radius: 8px;
}

.audio-manager__container label {
    color: black;
    display: flex;
    gap: 8px;
    padding-inline: 8px;
}

.audio-manager__container li:hover, .audio-manager__container li:focus {
    background-color: #e0f7fa;
}

@media (max-width: 500px) {
    .audio-manager__container label {
        font-size: 0.8rem;
        line-height: 1rem;
    }
}