.grant-permissions__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: white;
}

.grant-permissions__text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 24px;
}

.grant-permissions__text img {
    filter: invert(100%);
    width: 24px;
}

.grant-permissions__container button {
    color: white;
    border: 2px solid white;
    padding: 14px 0;
    font-size: 1rem;
    line-height: 19.2px;
    font-weight: 700;
    background-color: #232D7D;
    outline: none;
    border-radius: 48px;
    cursor: pointer;
    transition: all 0.25s;
}

.grant-permissions__container button:hover, .grant-permissions__container button:focus {
    background-color: white;
    color: #232D7D;
}